@tailwind base;
@tailwind components;
@tailwind utilities;

/* Globales */
*::after,
*::before,
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0 auto;
	font-family: 'Lato';
	color: #333;
}

.icon-cart {
	width: 40px;
	height: 40px;
	stroke: #000;
}

.icon-cart:hover {
	cursor: pointer;
}

img {
	max-width: 100%;
}

/* Header */
header {
	display: flex;
	justify-content: space-between;
	padding: 30px 0 40px 0;
}

.container-icon {
	position: relative;
}

.count-products {
	position: absolute;
	top: 55%;
	right: 0;

	background-color: #000;
	color: #fff;
	width: 25px;
	height: 25px;

	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

#contador-productos {
	font-size: 12px;
}

.container-cart-products {
	position: absolute;
	top: 50px;
	right: 0;

	background-color: #fff;
	width: 600px;
	z-index: 1;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.cart-product {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px;

	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.info-cart-product {
	display: flex;
	justify-content: space-between;
	flex: 0.8;
}

.titulo-producto-carrito {
	font-size: 20px;
}

.precio-producto-carrito {
	font-weight: 700;
	font-size: 20px;
	margin-left: 10px;
}

.cantidad-producto-carrito {
	font-weight: 400;
	font-size: 20px;
}

.icon-close {
	width: 25px;
	height: 25px;
	margin-top: 1rem;
}

.icon-close:hover {
	stroke: red;
	cursor: pointer;
}

.cart-total {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	gap: 20px;
}

.cart-total h3 {
	font-size: 20px;
	font-weight: 700;
}

.total-pagar {
	font-size: 20px;
	font-weight: 900;
}

.hidden-cart {
	display: none;
}

/* Main */
.container-items {
	margin-bottom: 80px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.item {
	border-radius: 10px;
}

.item:hover {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.item img {
	width: 100%;
	height: 300px;
	object-fit: cover;
	border-radius: 10px 10px 0 0;
	transition: all 0.5s;
}

.item figure {
	overflow: hidden;
}

.item:hover img {
	transform: scale(1.2);
}

.info-product {
	padding: 15px 30px;
	line-height: 2;
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.price {
	font-size: 18px;
	font-weight: 900;
}

/* .info-product button {
	border: none;
	background: none;
	background-color: #000;
	color: #fff;
	padding: 15px 10px;
	cursor: pointer;
} */

.cart-empty {
	padding: 20px;
	text-align: center;
}

.hidden {
	display: none;
}

.btn-clear-all {
	border: none;
	background-color: #000;
	color: #fff;
	padding: 15px 0;
	display: block;
	width: 100%;
	margin-top: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	font-family: inherit;
	cursor: pointer;
  font-size: 16px;
  transition: all .3s ease;
}

.btn-clear-all:hover{
  background-color: #333;
  transform: scale(1.03);
  border-radius: 0;
}

.ant-table-filter-dropdown-btns button {
  background-color: #1890ff;
  border-color: #1890ff;
  color: white;
}

/* Agrega este CSS a tu archivo de estilos */

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination a {
  color: #333;
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination a:hover {
  background-color: #f0f0f0;
}

.pagination .active a {
  background-color: #3498db;
  color: #fff;
}

.pagination a:focus {
  outline: none;
}

.text-home {
	font-size: 20px !important;
}

/* Estilos para pantallas más pequeñas */
@media only screen and (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination li {
    margin-right: 0;
    margin-bottom: 16px;
  }

	.container-cart-products {
		width: auto;
	}

	.icon-close {
		margin-top: 6px;
	}

	.periodoTitle {
		font-size: 21px;
	}
}
